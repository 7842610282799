import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Seo } from '../components';
import { StaticPage } from '../utils';
import {
  Blocks
} from '../components';

interface QueryResult {
  strapi: {
    staticPage: StaticPage,
  },
};

export const query = graphql`
  query($id: ID!) {
    strapi {
      staticPage(id: $id) {
        title
        blocks {
          __typename
          ... on STRAPI_ComponentContentRichText {
            id
            text
          }
          ... on STRAPI_ComponentContentPhotoList{
            title
            description
            photos {
              name
              function
              linkedInUrl
              mailTo
              institution
              photo {
                url
              }
            }
          }
          ... on STRAPI_ComponentContentWhiteLogoBar {
            logos {
              link
              logo {
                url
              }
            }
          }
          ... on STRAPI_ComponentContentDropletSectionList {
            title
            sections {
              title
              subTitle
              paragraph
              image {
                formats
              }
              buttonLabel
              buttonLink
            }
          }
          ... on STRAPI_ComponentContentCallToAction {
            title
            subTitle
            buttonLabel
            buttonLink
            buttonType
            color
          }
          ... on STRAPI_ComponentContentTimeline {
            title
            steps {
              title
              description
            }
          }
          ... on STRAPI_ComponentContentVideoEmbed {
            title
            embedCode
            thumbnail {
              url
            }
          }
          ... on STRAPI_ComponentContentTestimonialList {
            title
            testimonials {
              study
              quote
              name
              image {
                alternativeText
                id
                url
              }
              id
              excerpt
              age
            }
          }
        }
      }
    }
  }
`;

const StaticPageTemplate = ({ data }: { data: QueryResult }) => {
  const page = data.strapi.staticPage;

  return (
    <Layout>
      <Seo title={page.title} />
      <Blocks blocks={page.blocks} />
    </Layout>
  );
};

export default StaticPageTemplate;
